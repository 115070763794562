export const authMethod = {

  methods: {
    processAuthSuccess(data, methodName) {
      localStorage.setItem('jwt', data.token);
      localStorage.setItem('refresh_token', data.refresh_token);
      localStorage.setItem('last_login_method', methodName);

      const redirectedFrom = localStorage.getItem('redirected_from');
      if( redirectedFrom ){
        localStorage.setItem('redirected_from', '');
        window.location.href = redirectedFrom;
      } else {
        this.$router.push({name: 'Home'});
      }

    }
  }
}